const details = [
  {
    id: 48,
    name: "My Blog - Bits and Scribbles",
    image: "images/site.jpg",
    link: "https://www.bitsandscribbles.com/",
    language: "Node js, Express, MongoDb, EJS",
  },
  {
    id: 47,
    name: "Disappearing Notifications",
    image: "images/toastMessages.png",
    link: "https://disappearingnotifications.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 46,
    name: "Vertical Slider",
    image: "images/verticalSlider.png",
    link: "https://simpleverticalslider.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 45,
    name: "Greenish World",
    image: "images/greenish.png",
    link: "https://greenishworld.netlify.app/",
    language: "JavaScript, Phaser",
  },
  {
    id: 44,
    name: "Sticky Nav Demo",
    image: "images/sticky.png",
    link: "https://stickynavdemo.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 43,
    name: "Cards-Placeholder",
    image: "images/placeholder.png",
    link: "https://cards-placeholder.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 42,
    name: "Simple Drawing Pad",
    image: "images/drawing.png",
    link: "https://simpledrawingpad.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 41,
    name: "Simple CSS Loader",
    image: "images/loader.png",
    link: "https://simpleloader.netlify.app/",
    language: "CSS, HTML",
  },
  {
    id: 40,
    name: "Drag N Drop",
    image: "images/drag.png",
    link: "https://draganddropbasic.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 39,
    name: "Ripple effect",
    image: "images/ripple.png",
    link: "https://rippleeffectonbutton.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 38,
    name: "Clock⏰⌛",
    image: "images/clock.png",
    link: "https://ticktick-clock.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },

  {
    id: 37,
    name: "Image Slider",
    image: "images/slider.png",
    link: "https://mybackgroundslider.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 36,
    name: "Drink Water🥛🥛🥛",
    image: "images/water.png",
    link: "https://howmuchwater.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 35,
    name: "Incrementing Counter",
    image: "images/counter.png",
    link: "https://incrementing-counter-fake.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 34,
    name: "Animated Navigation",
    image: "images/navigation.png",
    link: "https://animated-navigation-large.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 33,
    name: "Random Choice Picker",
    image: "images/choices.png",
    link: "https://randomchoices.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 32,
    name: "FAQ Cards Toggle",
    image: "images/faq.png",
    link: "https://servicenow-faqs.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 31,
    name: "Event Key Codes",
    image: "images/keycodes.png",
    link: "https://eventkeycodesok.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 30,
    name: "Dad Jokes 😆",
    image: "images/joke.png",
    link: "https://sillydadjokes.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 29,
    name: "Wave effect",
    image: "images/wave.png",
    link: "https://waveffect.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 28,
    name: "Split landing page",
    image: "images/splitting.png",
    link: "https://split-landing-page-travel.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 27,
    name: "Blurry loading",
    image: "images/blurry.png",
    link: "https://curious-longma-010ea1.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 26,
    name: "Rotating menu and expanding search ",
    image: "images/rotatingmenu.png",
    link: "https://rotatingnavigationandexpandingmenu.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 25,
    name: "Progress bar ",
    image: "images/progressbar.png",
    link: "https://progressdemo.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 24,
    name: "Expanding cards",
    image: "images/expanding.png",
    link: "https://expanding-cards-portfolio.netlify.app/",
    language: "JavaScript, CSS, HTML",
  },
  {
    id: 23,
    name: "🍎 Apple Catcher 🧺",
    image: "images/apple_catcher.png",
    link: "https://superlative-shortbread-44dd7e.netlify.app/",
    language: "JavaScript, Phaser",
  },
  {
    id: 22,
    name: "🃏 Flash Cards (API) 🤔",
    image: "images/flash.png",
    link: "https://bejewelled-unicorn-e13d98.netlify.app/",
    language: "JavaScript, CSS, React, API",
  },
  {
    id: 21,
    name: "👀 Minion 👀",
    image: "images/minion.png",
    link: "https://steady-tiramisu-8d8866.netlify.app/",
    language: "JavaScript, CSS",
  },
  {
    id: 20,
    name: "🔮Magic Ball 8🔮",
    image: "images/magic.png",
    link: "https://timely-fox-b010dc.netlify.app/",
    language: "DOM, JavaScript, CSS",
  },
  {
    id: 19,
    name: "🍪Pac-Man 👻",
    image: "images/pacman.png",
    link: "https://timely-cranachan-0d6ab6.netlify.app/",
    language: "DOM, JavaScript, CSS",
  },
  {
    id: 18,
    name: "🐍Snake Game",
    image: "images/snake.png",
    link: "https://fluffy-pudding-0f0eac.netlify.app/",
    language: "DOM, JavaScript, CSS",
  },
  {
    id: 17,
    name: "🔠Guess the Word!",
    image: "images/guesstheword.png",
    link: "https://tourmaline-cajeta-b0d8d8.netlify.app/",
    language: "DOM, JavaScript",
  },
  {
    id: 16,
    name: "🗺️Map your workouts",
    image: "images/map.png",
    link: "https://effervescent-travesseiro-b371e9.netlify.app/",
    language:
      "ObjectOrientedProgramming, JavaScript, Leaflet, API, Geolocation",
  },
  {
    id: 15,
    name: "💰Fictive Bank Site",
    image: "images/banking.jpg",
    link: "https://stirring-kashata-29068c.netlify.app/",
    language:
      "Advanced DOM manipulation, Slide element, Sticky Navigation, DOM events, IntersectionObserver, JavaScript, CSS",
  },
  {
    id: 14,
    name: "🕹️Games board",
    image: "images/games_board.PNG",
    link: "https://golden-gnome-e8d793.netlify.app/",
    language: "DOM manipulation, JavaScript, CSS",
  },
  {
    id: 13,
    name: "❌Tic-Tac-Toe",
    image: "images/tictactoe.png",
    link: "https://snazzy-speculoos-db3893.netlify.app/",
    language: "DOM manipulation, JavaScript, CSS",
  },
  {
    id: 12,
    name: "💵Fictive Virtual Personal Banking",
    image: "images/fictive_bank.png",
    link: "https://clinquant-cajeta-42eed6.netlify.app/",
    language: "DOM manipulation, JavaScript, CSS",
  },
  {
    id: 11,
    name: "🤯Memory Game",
    image: "images/memory1.png",
    link: "https://vermillion-tapioca-55e5ed.netlify.app/",
    language: "DOM manipulation, JavaScript, CSS",
  },
  {
    id: 10,
    name: "🧩Slide Puzzle",
    image: "images/sponge.png",
    link: "https://extraordinary-peony-cd46ff.netlify.app/",
    language: "DOM manipulation, JavaScript, CSS",
  },
  {
    id: 9,
    name: "🎲Dice Game",
    image: "images/pig-game.png",
    link: "https://ornate-halva-c5686c.netlify.app/",
    language: "DOM Class manipulation, JavaScript, CSS",
  },
  {
    id: 8,
    name: "🔢Guess the number!",
    image: "images/Guess_the_number.png",
    link: "https://soft-pika-ed6b48.netlify.app/",
    language: "DOM manipulation, JavaScript",
  },
  {
    id: 7,
    name: "🏋️‍♀️Javascript- exercises",
    image: "images/javascript.png",
    link: "https://astonishing-pasca-5a36aa.netlify.app/",
    language: "CSS, HTML, Express.js, Node.js",
  },
  {
    id: 6,
    name: "🪄The Journey Within",
    image: "images/journey.png",
    link: "https://www.victoriamoonteanu.com/",
    language: "CSS, HTML, Express.js, Node.js",
  },
  {
    id: 5,
    name: "Site Template",
    image: "images/extraterestrial.png",
    link: "https://vicmoon.github.io/CSS_SITE/",
    language: "CSS, HTML, Responsive Template",
  },
  {
    id: 4,
    name: "JavaScript Game",
    image: "images/game.png",
    link: "https://vicmoon.github.io/Javascript_Mainproject/",
    language: "CSS, HTML, JavaScript Game",
  },
  {
    id: 3,
    name: "React Dictionary",
    image: "images/Dictionary.JPG",
    link: "https://inspiring-tanuki-a897c7.netlify.app/",
    language: "CSS, API, HTML, JavaScript, React",
  },
  {
    id: 2,
    name: "Creative Project",
    image: "images/creatives.JPG",
    link: "https://playful-cuchufli-58a84f.netlify.app/",
    language: "CSS, HTML, Responsive Creative",
  },
  {
    id: 1,
    name: "World Clock App",
    image: "images/clock.JPG",
    link: "https://phenomenal-unicorn-f160f9.netlify.app/",
    language: "CSS, HTML, JavaScript, API, Responsive, moment.js",
  },
  {
    id: 0,
    name: "First Landing Page",
    image: "images/newlanding_page.JPG",
    link: "https://s3.amazonaws.com/shecodesio-production/challenge_submissions/files/001/286/709/original/basicaddon.html?1668786913",
    language: "CSS, HTML, Responsive",
  },
  {
    id: -1,
    name: "Weather App in React",
    image: "images/weather.JPG",
    link: "https://illustrious-beignet-228d77.netlify.app/",
    language: "React, CSS, HTML",
  },
  {
    id: -2,
    name: "JavaScript Weather App",
    image: "images/vanilajs.JPG",
    link: "https://vanillaweathersearch.netlify.app/",
    language: "JavaScript",
  },
  {
    id: -3,
    name: "Simple Landing Page",
    image: "images/landing.jpg",
    link: "https://s3.amazonaws.com/shecodesio-production/challenge_submissions/files/000/874/197/original/Finalproject.html?1653155333",
    language: "HTML, CSS",
  },
];

export default details;
